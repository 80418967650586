import React from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider, useIsFetching } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ClipLoader from 'react-spinners/ClipLoader';

import Toast from './components/common/toast';
import 'react-toastify/dist/ReactToastify.min.css';

import './App.css';

import Routes from 'routes';
import store from 'store/store';
import history from 'utils/history';

// Create a client

const queryClient = new QueryClient();

function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();

  return isFetching ? (
    <div className="floating-loader">
      <ClipLoader loading={isFetching} />
    </div>
  ) : null;
}

function App() {
  return (
    <div className="main-container">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <GlobalLoadingIndicator />
          <Routes history={history} />
          <Toast />
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
