// Use a Toast Library

import { notify } from 'components/common/toast';

export function success({ title, message }) {
  notify({ type: 'success', message: { title, message } });
}

export function error({ title, message }) {
  notify({ type: 'danger', message: { title, message } });
}

export function warning({ title, message }) {
  notify({ type: 'warning', message: { title, message } });
}

export function handleError(event, { title, message } = {}) {
  console.error(event);
  const { data: response } = event.response || {};

  const messages = (response?.errors && Object.values(response?.errors)?.flat()?.[0]) || response.message;

  const errorMessage = messages || message;
  const errorTitle = title || 'Error';

  error({ title: errorTitle, message: errorMessage });
}
