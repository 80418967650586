import { GET, POST, PUT } from 'constants/appConstant';
import endpoints from 'constants/endpoints';
import http from 'utils/http';
import { interpolate } from 'utils/string';

export const getUserFullName = ({ firstName = 'Technorio', lastName = 'Member' } = {}) => firstName + ' ' + lastName;

/**
 * Fetch user by id.
 *
 * @param {number} id
 */
export const fetchUserById = async (id) => {
  const { data } = await http(GET, interpolate(endpoints.users.show, id));

  return data.data;
};

/**
 * Fetch all users.
 *
 * @returns {Promise<object>}
 */
export const fetchUsers = async () => {
  const { data } = await http(GET, endpoints.users.all);

  return data.data;
};

/**
 * Update user.
 *
 * @param {number} id
 * @param {object} userData
 * @returns
 */
export const updateUser = async (id, userData) => {
  const { data } = await http(PUT, interpolate(endpoints.users.show, id), { body: userData });

  return data.data;
};

/**
 * Create user.
 */
export const createUser = async (userData) => {
  const { data } = await http(POST, endpoints.users.all, { body: userData });

  return data.data;
};
