import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';

const Loading = () => {
  const container = useRef(null);
  useEffect(() => {
    if (!container) return;

    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./loading.json')
    });
  }, [container]);

  return (
    <div className="loading-container">
      <div className="loading" ref={container}></div>
    </div>
  );
};

export default Loading;
