import { withRouter } from 'react-router';
import Pagination from 'react-js-pagination';
import { NavLink, useHistory } from 'react-router-dom';
import React, { useState, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import WfoStatus from './WfoStatus';
import WfoHeader from './WfoHeader';
import { format } from 'utils/datetime';
import { Loading } from 'components/loading';
import { getUserFullName } from 'services/userServices';
import { success, error as errorToast } from 'utils/toast';
import { WFOS_INDEX, WFOS_REQUESTED } from 'constants/routes';
import { DEFAULT_DATE_TIME_FORMAT, WFH_STATUS, WFO } from 'constants/appConstant';
import { updateWfoStatus, fetchRequestedWfos } from 'services/wfoServices';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

const WfoApproveTable = () => {
  const history = useHistory();
  const [meta, setMeta] = useState([]);
  const queryClient = useQueryClient();

  const { user: authUser } = useSelector((state) => state.data.auth);

  const [queries, setQueries] = useState({ role: authUser.userAttributes?.isAdmin ? 'admin' : 'manager' });

  const { data, isLoading } = useQuery(['wfos', ...Object.values(queries)], () => fetchRequestedWfos(queries));

  const mutation = useMutation(({ id, status }) => updateWfoStatus(id, status), {
    onMutate: async ({ id, status }) => {
      await queryClient.cancelQueries(['wfos', ...Object.values(queries)]);

      const previousData = queryClient.getQueryData(['wfos', ...Object.values(queries)]);

      queryClient.setQueryData(['wfos', ...Object.values(queries)], {
        ...previousData,
        data: previousData.data.map((wfo) => (wfo.id == id ? { ...wfo, status } : wfo))
      });

      return { previousData };
    },
    onError: (error, { id, status }, context) => {
      const { data } = error.response;
      const messages = data?.errors && Object.values(data?.errors)?.flat();

      errorToast({ message: messages?.[0] || data?.message });

      queryClient.setQueryData(['wfos', ...Object.values(queries)], context.previousWfos);
    },
    onSettled: (_newData, error) => {
      queryClient.invalidateQueries(['wfos', ...Object.values(queries)]);
    },
    onSuccess: (data, { id, status }) => {
      success({ message: `WFO status updated to ${WFH_STATUS[status]}. ` });
    }
  });

  useEffect(() => {
    if (!data) return;
    setMeta({ ...data });
  }, [data]);

  const { currentPage, perPage, total } = meta;

  const handleRowClick = (wfoId) => {
    return history.push({
      pathname: `/wfos/${wfoId}`
    });
  };

  const handleUpdateStatus = async (e, wfo, approved) => {
    e.stopPropagation();
    let updatedStatus = '';
    if (approved) {
      updatedStatus = wfo.status === 'REQUESTED' ? 'REQUEST_APPROVED' : 'REPORT_APPROVED';
    } else {
      updatedStatus = wfo.status === 'REQUESTED' ? 'REQUEST_REJECTED' : 'REPORT_REJECTED';
    }

    mutation.mutate({ id: wfo.id, status: updatedStatus });
  };

  const handlePageChange = (page) => {
    setQueries((queries) => ({
      ...queries,
      page
    }));
  };

  return (
    <div>
      <WfoHeader heading={WFO}>
        <div className="card col-12">
          <div className="row">
            <div className="col-12">
              <NavLink
                exact
                activeStyle={{ background: '#94c82a' }}
                className="btn btn-outline-primary btn-sm m-2"
                to={WFOS_INDEX}
              >
                My History
              </NavLink>
              <NavLink
                exact
                activeStyle={{ background: '#94c82a' }}
                className="btn btn-outline-primary btn-sm m-2"
                to={WFOS_REQUESTED}
              >
                Approve WFO
              </NavLink>
            </div>
          </div>
        </div>
      </WfoHeader>
      <div className="container-fluid mt--6">
        <div className="row">
          <div className="card col-12">
            <div className="card-header border-0">
              <h3 className="mb-0 text-body">WFO REQUEST</h3>
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <div className="table-responsive">
                <table className="table align-items-center table-flush table-hover">
                  <thead>
                    <tr>
                      <th scope="col" className="sort">
                        WFO ID
                      </th>
                      <th scope="col" className="sort">
                        Name
                      </th>
                      <th scope="col" className="sort">
                        WFO Date
                      </th>
                      <th scope="col" className="sort">
                        Requested At
                      </th>
                      <th scope="col" className="sort">
                        Reported At
                      </th>
                      <th scope="col" className="sort">
                        Status
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {data &&
                      data.data.map((wfo, index) => (
                        <tr key={index} onClick={() => handleRowClick(wfo.id)} className="pointer">
                          <th scope="row">
                            <div className="media align-items-center">
                              <div className="media-body">
                                <span className="name mb-0 text-sm">{wfo.id}</span>
                              </div>
                            </div>
                          </th>
                          <th scope="row">
                            <div className="media align-items-center">
                              <div className="media-body">
                                <span className="name mb-0 text-sm">{getUserFullName(wfo.user)}</span>
                              </div>
                            </div>
                          </th>
                          <th scope="row">
                            <div className="media align-items-center">
                              <div className="media-body">
                                <span className="name mb-0 text-sm">{wfo.attendanceDate}</span>
                              </div>
                            </div>
                          </th>
                          <td>{format(wfo.requestedOn, DEFAULT_DATE_TIME_FORMAT)}</td>
                          <td>{wfo.reportedOn ? format(wfo.reportedOn, DEFAULT_DATE_TIME_FORMAT) : '-'}</td>
                          <td>
                            <WfoStatus status={wfo.status} />
                          </td>
                          <td>
                            {(wfo.status === 'REQUESTED' || wfo.status === 'REPORT_SUBMITTED') &&
                              authUser.id === wfo.user.userAttributes.managerId && (
                                <>
                                  <button
                                    className="btn btn-sm btn-outline-success"
                                    onClick={(e) => handleUpdateStatus(e, wfo, true)}
                                    type="button"
                                  >
                                    APPROVE
                                  </button>
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={(e) => handleUpdateStatus(e, wfo, false)}
                                    type="button"
                                  >
                                    REJECT
                                  </button>
                                </>
                              )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="mt-3 d-flex justify-content-end">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    onChange={(page) => handlePageChange(page)}
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText={<FontAwesomeIcon icon={faAngleLeft} />}
                    nextPageText={<FontAwesomeIcon icon={faAngleRight} />}
                    firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                    lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                    hideDisabled={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WfoApproveTable);
